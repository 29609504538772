/* istanbul ignore file */
/* eslint-disable */

let loaded = false

export const loadGtm = async () => {
  const enabled = process.env.VUE_APP_TRACKING_ENABLED === 'ON'
  if (!enabled || loaded) return

  const i = process.env.VUE_APP_GTM_CONTAINER_ID
  const w = window; const d = document
  const s = 'script'; const l = 'dataLayer'

  w[l] = w[l] || []
  w[l].push({
    'gtm.start':
      new Date().getTime(),
    event: 'gtm.js'
  })
  const f = d.getElementsByTagName(s)[0]
  const j = d.createElement(s); const dl = l !== 'dataLayer' ? '&l=' + l : ''
  j.async = true
  j.src =
    'https://www.googletagmanager.com/gtm.js?id=' + i + dl
  f.parentNode.insertBefore(j, f)
  loaded = true
}
