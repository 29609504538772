import { elb } from '@elbwalker/walker.js'
import { WALKER_COMMANDS } from '@/services/helpers/ElbwalkerHelper'

export const loadElbwalker = (consent) => {
  if (!consent) return // Process only explicit consent

  // Renaming of the categories
  const mapping = {
    necessary: 'functional',
    functional: 'functional',
    performance: 'analytics',
    advertising: 'marketing',
  }

  const state = {}
  Object.keys(consent).forEach(function (category) {
    state[mapping[category] || category] = consent[category]
  })

  elb(WALKER_COMMANDS.consent, state)
}
